import { Component } from "react";
import { connect } from "react-redux";
import { SDate, SForm, SHr, SIcon, SLoad, SNavigation, SPage, STable2, SText, STheme, SView } from "servisofts-component";
import SSocket from "servisofts-socket";
import Botoncito from "../../Components/Botoncito";
import Model from "../../Model";
import sucursal_usuario from "../sucursal_usuario";
import Progreso from "../Progreso";


class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {};

  }
  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ loading: "cargando", data: null });
    SSocket.sendPromise({
      component: "progreso",
      type: "getAll",
    })
      .then((resp) => {
        this.setState({ loading: false, data: resp.data });
      })
      .catch((e) => {
        this.setState({ loading: false, error: e });
      });
  }

  getLista() {
    var data = this.state.data;
    if (!data) return <SLoad />

    console.log("data", data)
    // data = {}
    if (Object.keys(data).length == 0) {
      return (<SView col={"xs-12"} center>
        <SIcon name={"Alert"} width={50} height={50} />
        <SHr height={16} />
        <SText center>No hay datos de Progreso</SText>
      </SView >)
    }

    // return <SText>{`json: ${JSON.stringify(data, "\n", "\t")}`}</SText>

    return <SText>{`json`}</SText>

  }

  getForm() {

    return <SForm
      ref={(ref) => { this.form = ref; }}
      row
      center
      style={{
        justifyContent: "space-between",
      }}
      inputProps={{
        col: "xs-10",
        customStyle: "calistenia",

      }}
      inputs={{
        peso: { placeholder: "Peso", type: "number", isRequired: true, label: "Peso", },
        imc: { placeholder: "IMC", type: "number", isRequired: true, },
        grasa: { placeholder: "% Grasa corporal", type: "number", isRequired: true, },
        mbrazo: { placeholder: "Medida Brazo", type: "number", isRequired: false, },
        mespalda: { placeholder: "Medida Espalda", type: "number", isRequired: false, },
        mpierna: { placeholder: "Medida Pierna", type: "number", isRequired: false, },
        mcintura: { placeholder: "Medida Cintura", type: "number", isRequired: false, },
        mgluteo: { placeholder: "Medida Gluteo", type: "number", isRequired: false, },
      }}
      // onSubmitProps={{
      //   type: (this.state.tipoSelect != "ingreso" ? "danger" : "success")
      // }}
      onSubmitName={"Guardar"}
      onSubmit={(values) => {
        // this.state.onLoad = true;
        // console.log("values", values)
        // this.setState({ loading: "cargando", data: null });
        // var object = {
        //   component: "progreso",
        //   type: "registro",
        //   estado: "cargando",
        //   key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
        //   data: {
        //     ...values,
        //     key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
        //     mes: 2,
        //   },
        // }
        // SSocket.send(object, true);
        // this.state.onLoad = false;

        // this.setState({ loading: "cargando", data: null });
        var object = {
          component: "progreso",
          type: "editar",
          estado: "cargando",
          key_usuario: this.props.state.usuarioReducer.usuarioLog.key,

          // key_sucursal: key_sucursal,
          // key_usuario: props.state.usuarioReducer.usuarioLog.key,

          data: {
            ...values,
            key_usuario: this.props.state.usuarioReducer.usuarioLog.key,
            key: 'eceb0658-3997-4711-81c1-e15eaa4ebdc9',
            estado: 100,
            mes: "100",
          },
        }
        SSocket.send(object, true);


        // if (this.key) {
        //   Usuario.Actions.editar({
        //     ...this.usr,
        //     ...values
        //   }, this.props);

        // } else {
        //   Usuario.Actions.registro(values, this.key_rol, this.props);
        // }

      }}
    />
  }


  render() {
    return (
      <SPage title={"Progreso"}>
        <SView center>

          {this.getLista()}
          {this.getForm()}
          <SHr height={32} />
        </SView>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(Test);