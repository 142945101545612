import { SPageListProps } from 'servisofts-component'
import Actions from './Actions'

const Pages: SPageListProps = {

}

import progresoReducer from './Reducer/progresoReducer'
const Reducers = {
  progresoReducer
}


export default {
  Pages,
  Actions,
  Reducers
};